/* eslint-disable */
import { gsap } from 'gsap'
import Swiper from 'swiper'
import { FreeMode, Grid, Navigation } from 'swiper/modules'
import { isMobile, isTablet, slideToggle } from '../utils'
class HomePage {
  init() {
    if (!document.querySelector('.home-hero')) return
    this.startAnimation()
    this.benefitVideo()

    if (isTablet()) {
      this.tasksTablet()
      this.infoCardTablet()
    }

    if (isMobile()) {
      this.mobileInfoCaption()
      this.mobileClientSlider()
      this.mobileAlgorithmsCaption()
    }

    this.clientsSlider()
  }

  startAnimation() {
    const headerTimeline = gsap.timeline()
    headerTimeline.fromTo(
      '.header',
      {
        opacity: 0,
        y: 70,
      },
      {
        delay: 0.5,
        opacity: 1,
        y: 0,
      }
    )
  }

  clientsSlider() {
    Swiper.use([Navigation, Grid, FreeMode])
    const slider = document.querySelector('.home-info-clients__slider')
    const swiper = new Swiper(slider, {
      navigation: {
        prevEl: '.home-info-clients__prev',
        nextEl: '.home-info-clients__next',
      },
      breakpoints: {
        0: {
          direction: 'horizontal',
          slidesPerView: 'auto',
          spaceBetween: 13,
          grid: null,
          freeMode: true,
        },
        767: {
          direction: 'horizontal',
          slidesPerView: 1,
          spaceBetween: 44,
          freeMode: false,
          grid: {
            fill: 'column',
            rows: 2,
          },
        },
        991: {
          direction: 'vertical',
          slidesPerView: 2,
          spaceBetween: 44,
          grid: null,
        },
      },
    })
  }

  tasksTablet() {
    document.querySelectorAll('.home-tasks-list-item').forEach((el) => {
      const title = el.querySelector('.home-tasks-list-item__title')
      const icon = el.querySelector('.home-tasks-list-item__icon')
      const hide = el.querySelector('.home-tasks-list-item__hide')
      const show = el.querySelector('.home-tasks-list-item__show')
      const caption = el.querySelector('.home-tasks-list-item__caption')
      const head = document.createElement('div')
      head.classList.add('home-tasks-list-item__head')
      head.append(icon)
      head.append(title)
      head.append(show)
      head.append(hide)

      head.onclick = () => {
        el.classList.toggle('home-tasks-list-item_showed')
        slideToggle(caption)
      }

      el.prepend(head)
    })
  }

  infoCardTablet() {
    document.querySelectorAll('.home-info-card').forEach((el) => {
      const navigation = el.querySelector('.home-info-card-navigation')
      if (navigation) {
        const bottom = document.createElement('div')
        bottom.classList.add('home-info-card__bottom')
        bottom.append(navigation)

        el.append(bottom)
      }
    })
  }

  mobileInfoCaption() {
    document.querySelectorAll('.home-info-caption').forEach((el) => {
      const title = el.querySelector('.home-info-caption__title')
      const show = el.querySelector('.home-info-caption__show')
      const hide = el.querySelector('.home-info-caption__hide')
      const text = el.querySelectorAll('.home-info-caption__text')
      const logos = el.querySelector('.home-info-caption__logos')
      const head = document.createElement('div')
      head.classList.add('home-info-caption__head')
      head.append(title)
      head.append(show)
      head.append(hide)

      const body = document.createElement('div')
      body.classList.add('home-info-caption__body')
      text.forEach((el) => {
        body.append(el)
      })
      if (logos) {
        body.append(logos)
      }

      head.onclick = () => {
        slideToggle(body)
        el.classList.toggle('home-info-caption_showed')
      }

      el.prepend(body)
      el.prepend(head)
    })
  }
  mobileClientSlider() {
    const links = document.querySelectorAll('.home-info-clients__link')
    let slides = document.querySelectorAll('.home-info-clients__slide')
    const wrapper = document.querySelector(
      '.home-info-clients__slider .swiper-wrapper'
    )
    const linksLength = links.length
    const slidesLength = slides.length

    if (linksLength / 2 > slidesLength) {
      const different = Math.ceil(linksLength / 2 - slidesLength)
      let i = 0

      while (different > i) {
        const slide = document.createElement('div')
        slide.classList.add('swiper-slide', 'home-info-clients__slide')
        wrapper.append(slide)
        i++
      }
      slides = document.querySelectorAll('.home-info-clients__slide')
    }
    slides.forEach((el, i) => {
      el.innerHTML = ''
      if (links.item(i * 2)) {
        el.append(links.item(i * 2))
      }
      if (links.item(i * 2 + 1)) {
        el.append(links.item(i * 2 + 1))
      }
    })
  }

  mobileAlgorithmsCaption() {
    document.querySelectorAll('.home-algorithms-for').forEach((el) => {
      el.previousElementSibling.append(el)
    })
    document
      .querySelectorAll('.home-algorithms-caption__title')
      .forEach((el) => {
        el.onclick = () => {
          slideToggle(el.nextElementSibling)
          el.parentElement.classList.toggle('home-algorithms-caption_showed')
        }
      })
  }

  benefitVideo() {
    const iframe = document.querySelector('.home-benefits-video__iframe')
    const play = document.querySelector('.home-benefits-video__play')
    play.onclick = () => {
      play.parentElement.classList.add('home-benefits-video_play')
      iframe.contentWindow.postMessage(
        '{"event":"command","func":"' + 'playVideo' + '","args":""}',
        '*'
      )
    }
  }
}

export const homePage = new HomePage()
/* eslint-enable */
